import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"
import { Product } from "../../widgets/Product"

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* Helpers */
import { withRouter } from '../../helpers'

/* Icons */
import { LoadingOutlined } from "@ant-design/icons"

/* REST API */
import { search as find } from '../../api/Product'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'


/* Page Search */
class Search extends Component {

    constructor() {
        super()

        this.state = {
            status: 'loading',
            data: []
        }
    }

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
        this.load()
    }

    load = () => {

        const { params } = this.props
        const { text } = params
        const search = text ? text : ""

        find({ text: search }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, status: 'ok' })
            }
            else {
                this.setState({ data: null, status: 'error' })
            }
        }).catch(() => {
            this.setState({ data: null, status: 'network' })
        })

    }

    render = () => {

        const { params } = this.props
        const { text } = params
        const search = text ? text : ""

        const meta = {
            title: `Поиск | General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехника Алматы, реализация спецтехники, оборудование для различных отраслей, качество и надежность, продажа техники Алматы, строительное оборудование, сельскохозяйственная техника, горная техника, транспортные средства, услуги по продаже спецтехники, специализированное оборудование'
                }
            }
        }

        const { status, data } = this.state

        if (status !== "ok") {
            return (

                <DocumentMeta {...meta}>
                    <div className="page">

                        <Header search={search} />
                        <div className="product-page-loading">
                            <LoadingOutlined />
                        </div>

                        <Footer />
                    </div>
                </DocumentMeta>
            )
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header search={search} />
                    <div className="page-content">

                        {data.length === 0 &&
                            <h1>По вашему запросу "{search}"<br /> ничего не найдено!</h1>
                        }

                        {data.length > 0 &&
                            <div className="search-wrapper">

                                <h1>Поиск "{search}"<br /></h1>

                                <div className="catalog-products">

                                    <div className="catalog-products-inner">
                                        {data.map((product, index) => <Product data={product} key={`${index}`} />)}
                                    </div>

                                </div>
                            </div>
                        }
                    </div>

                    <Footer />

                </div>
            </DocumentMeta >
        )
    }

}

export default withRouter(Search)