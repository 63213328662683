import React, { Component } from "react"

/* Components */
import { Pagination } from '../../components'

/* Widgets */
import { Header, Footer } from "../../widgets/Page"
import { Product } from "../../widgets/Product"

/* React router */
import { Link, NavLink } from "react-router-dom"

/* REST API */
import { get, products } from '../../api/Catalog'

/* Icons */
import { LoadingOutlined } from "@ant-design/icons"

/* Helpers */
import { withRouter } from '../../helpers'

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'


/* Page Catalog */
class Catalog extends Component {

    constructor() {
        super()

        this.state = {
            status: 'loading',
            data: null,
            category: null,

            products: [],
            productLoading: false,

            brand: "",
            page: 1,
            count: 0
        }
    }

    componentDidMount = () => {
        this.load()
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
    }

    componentDidUpdate = (prevProps) => {

        const { params, type } = this.props

        if (params.slug !== prevProps.params.slug) {
            this.setState({ page: 1 }, () => this.products(params.slug))
        }

        if (type !== prevProps.type) {
            this.load(type)
        }

    }

    /* Load Data */
    load = (type = this.props.type) => {

        const { params } = this.props
        const slug = params && params.slug ? params.slug : ''

        get({ type, slug }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, products: response.data.products, category: response.data.category, count: response.data.count, status: 'ok' })
            }
            else {
                this.setState({ data: null, status: 'error' })
            }
        }).catch(() => {
            this.setState({ data: null, status: 'network' })
        })
    }

    /* Load Products */
    products = (category = '') => {

        const { page, brand } = this.state
        const { params, type } = this.props
        const slug = category === '' ? params && params.slug ? params.slug : '' : category

        this.setState({ productLoading: true })

        products({ slug, page, brand, type }).then(response => {
            if (response.status === 200) {
                this.setState({ products: response.data.products, count: response.data.count, category: response.data.category, status: 'ok' })
            }
            else {
                this.setState({ products: [], status: 'error' })
            }
        }).catch(() => {
            this.setState({ products: [], status: 'network' })
        }).finally(() => {
            this.setState({ productLoading: false })
        })
    }

    _products = () => {

        const { products, count, page, productLoading } = this.state

        if (productLoading) {
            return (
                <div className="catalog-loading">
                    <LoadingOutlined />
                </div>
            )
        }

        if (products && Array.isArray(products) && products.length === 0) {
            return (
                <div className="catalog-products-empty">
                    <h3>Ничего не найдено</h3>
                </div>
            )
        }

        if (products && Array.isArray(products) && products.length > 0) {

            return (
                <div className="catalog-products">

                    <div className="catalog-products-inner">
                        {products.map((product, index) => <Product data={product} key={`${index}`} />)}
                    </div>

                    <Pagination size={12} page={page} total={count} onPageChange={page => this.setState({ page }, () => this.products())} />

                </div>
            )
        }

    }

    /* Draw Content */
    _content = () => {

        const { data, status, brand } = this.state
        const { type } = this.props

        const link = type === "truck" ? "/catalog/" : "/parts/"

        if (status !== "ok") {
            return (
                <div className="catalog-loading">
                    <LoadingOutlined />
                </div>
            )
        }

        return (
            <div className="catalog-container">

                <div className="catalog-menu">

                    {data && data.categories && Array.isArray(data.categories) && data.categories.length > 0 &&
                        <div className="catalog-category">
                            {data.categories.map((category, index) =>
                                <NavLink to={`${link}category/${category.slug}`} key={`${index}`}>
                                    {category.name}
                                </NavLink>
                            )}
                        </div>
                    }

                    {data && data.brands && Array.isArray(data.brands) && data.brands.length > 0 &&
                        <div className="catalog-brand">
                            {data.brands.map((item, index) =>
                                <div onClick={() => this.setState({ brand: brand === item.slug ? "" : item.slug }, () => this.products())} key={`${index}`} className="catalog-brand-item">
                                    <div className={`catalog-brand-checked ${brand === item.slug ? 'active' : 'default'}`}>
                                        {brand === item.slug ? <img src="/images/ok.png" alt="OK" /> : null}
                                    </div>
                                    {item.name}
                                </div>
                            )}
                        </div>
                    }

                </div>

                {this._products()}

            </div>
        )
    }

    render = () => {

        const { category } = this.state
        const { type } = this.props
        const title = category ? category.name : type === "truck" ? "Каталог" : "Запчасти"
        const url = category ? `${type === "truck" ? "/catalog" : "/parts"}/category/${category.slug}` : type === "truck" ? "/catalog" : "/parts"

        const meta = {
            title: `${title} | General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}${url}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехника Алматы, реализация спецтехники, оборудование для различных отраслей, качество и надежность, продажа техники Алматы, строительное оборудование, сельскохозяйственная техника, горная техника, транспортные средства, услуги по продаже спецтехники, специализированное оборудование'
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header back />

                    <div className="page-content">
                        <div className="catalog-wrapper">

                            <h1>{title}</h1>
                            <div className="bread-crump">
                                <Link to="/">Главная</Link>
                                {category ? type === "truck" ? <Link to="/catalog">Каталог</Link> : <Link to="/parts">Запчасти</Link> : <span>{title}</span>}
                                {category ? <span>{category.name}</span> : null}
                            </div>

                            {this._content()}
                        </div>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta>
        )
    }

}

export default withRouter(Catalog)