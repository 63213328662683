import axios from "axios"
import { env } from '../constants'

/* Get */
export const get = data => {
    const uri = `${env.startpoint}product/get`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* Search */
export const search = data => {
    const uri = `${env.startpoint}product/search`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}
