import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"

/* Player */
import ReactPlayer from 'react-player'

/* Redux */
import { connect } from "react-redux"

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'

/* Page Services */
class Services extends Component {

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
    }

    request = text => {

        /* Fields */
        const { setMessage, setRequest } = this.props

        const message = `Заявка на услугу: ${text}`

        setMessage(message)
        setRequest(true)

    }

    render = () => {

        const meta = {
            title: `Сервисы | General Machinery Group`,
            description: 'ТОО «GENERAL MACHINERY GROUP» предлагает выездную бригаду во главе с главным механиком для обслуживания техники на дальних расстояниях. В случае поломки клиент может выбрать устранение проблемы на месте или в специализированном сервисном центре, расположенном в Алматы. Сервисный центр оборудован тремя современными боксами для качественного обслуживания клиентов.',
            canonical: `${env.link}/services`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'ТОО General Machinery Group, выездная бригада, сервисное обслуживание, главный механик, поломка спецтехники, спец СТО, Алматы, сервисный центр, обслуживание клиентов, оборудование для спецтехники, гарантийные обязательства'
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header back />

                    <div className="page-content">
                        <h1>Сервисы</h1>
                        <div className="services">
                            <div className="services-wrapper">
                                <div className="services-container">

                                    <div onClick={() => this.request("Склад временного хранения (СВХ)")} className="services-item">
                                        <div className="services-item-inner">
                                            <img src="/services/warehouse.png" alt="Warehouse" />
                                            <div className="services-item-text">Склад временного хранения (СВХ)</div>
                                            <div className="services-item-button">Заказать услугу</div>
                                        </div>
                                    </div>

                                    <div onClick={() => this.request("Предпродажную подготовку спецтехники")} className="services-item">
                                        <div className="services-item-inner">
                                            <img src="/services/clipboard.png" alt="Clipboard" />
                                            <div className="services-item-text">Предпродажную подготовку спецтехники</div>
                                            <div className="services-item-button">Заказать услугу</div>
                                        </div>
                                    </div>

                                    <div onClick={() => this.request("Техническое обслуживание спецтехники")} className="services-item">
                                        <div className="services-item-inner">
                                            <img src="/services/car-repair.png" alt="Settings" />
                                            <div className="services-item-text">Техническое обслуживание спецтехники</div>
                                            <div className="services-item-button">Заказать услугу</div>
                                        </div>
                                    </div>

                                    <div onClick={() => this.request("Гарантийный ремонт спецтехники")} className="services-item">
                                        <div className="services-item-inner">
                                            <img src="/services/guarantee.png" alt="Quarantee" />
                                            <div className="services-item-text">Гарантийный ремонт спецтехники</div>
                                            <div className="services-item-button">Заказать услугу</div>
                                        </div>
                                    </div>

                                    <div onClick={() => this.request("Диагностику неисправностей спецтехники")} className="services-item">
                                        <div className="services-item-inner">
                                            <img src="/services/computer.png" alt="Computer" />
                                            <div className="services-item-text">Диагностику неисправностей спецтехники</div>
                                            <div className="services-item-button">Заказать услугу</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="service-information">

                                    <div className="service-information-text">
                                        <p>ТОО «GENERAL MACHINERY GROUP» согласно по гарантийным обязательством предоставляет выездную бригаду во главе с главным механиком, если техника находиться на дальних расстояний эксплуатации, а так же на выбор клиента при возникновение поломки устранить проблему в "СПЕЦ СТО" по месту дислокации. На территории офиса находиться  сервисный центр с 3-мя оборудованными боксами для обслуживание клиентов из города Алматы.</p>

                                        <div className="service-information-danger">
                                            <div className="service-information-danger-title">Выбирайте лучшую технику</div>
                                            <div className="service-information-danger-text">Мы поставляем любую тяжелую технику. Нам доверяют 50 строительных компаний. Мы работаем на рынке больше 5 лет и гарантируем надежность и качество.</div>
                                        </div>

                                        <p>В области ремонта спецтехники ТОО «GENERAL MACHINERY GROUP» осуществляет практически любые виды ремонтных и сервисных работ включающие:</p>

                                        <p>•⁠  ⁠Предпродажную подготовку спецтехники;</p>
                                        <p>•⁠  ⁠Техническое обслуживание спецтехники (ТО);</p>
                                        <p>•⁠  ⁠Гарантийный ремонт спецтехники;</p>
                                        <p>•⁠  ⁠Диагностику неисправностей спецтехники;</p>
                                        <p>•⁠  ⁠Текущий ремонт спецтехники (ТР);</p>

                                        <p>К техническому обслуживанию (ТО) техники относятся такие работы как:</p>
                                        <p>•⁠  ⁠замена масла, фильтров двигателя</p>
                                        <p>•⁠  ⁠замена масла, фильтрирующих элементов КПП, АКПП</p>
                                        <p>•⁠  ⁠замена охлаждающей жидкости</p>
                                        <p>•⁠  ⁠замена масла в бортовых механизмах и мостах и т.д.</p>

                                        <p>Для того чтобы узнать стоимость или подать заявку на выполнение работ, вам заполнить заявку и мы с вами свяжемся!</p>
                                    </div>

                                    <div className="service-information-video">
                                        <ReactPlayer
                                            url={`${env.mediapoint}/video/service.mov`}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta>
        )
    }

}

const mapStateToProps = state => {
    return {
        request: state.request,
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data }),
        setMessage: data => dispatch({ type: 'SET_MESSAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)