import React, { Component } from "react"

/* Router */
import { NavLink } from 'react-router-dom'

/* Fields */
const MENU = [
    { link: "/", name: "Главная", image: <img src="/images/menu/home.png" alt="Home" /> },
    { link: "/mobile/catalog", name: "Каталог", image: <img src="/images/menu/catalog.png" alt="Catalog" /> },
    { link: "/svh", name: "СВХ", image: <img src="/images/menu/warehouse.png" alt="Spare" /> },
    { link: "/leasing", name: "Лизинг", image: <img src="/images/menu/calculator.png" alt="Calculator" /> },
    { link: "/more", name: "Еще", image: <img src="/images/menu/more.png" alt="More" /> },
]


/* Component Mobile Tab */
class MobileTab extends Component {

    render = () => {
        return (
            <div className="mobile-tab">
                {MENU.map((item, index) =>
                    <NavLink to={`${item.link}`} className="mobile-tab-item" key={`${index}`}>
                        {item.image}
                        <div className="mobile-tab-name">{item.name}</div>
                    </NavLink>
                )}
                <a href="tel: +7 (771) 191-01-12" className="mobile-tab-phone">
                    <img src="/images/phone.png" alt="Phone" />
                    Позвонить
                </a>
            </div>
        )
    }

}

export default MobileTab