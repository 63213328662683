import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"

/* Player */
import ReactPlayer from 'react-player'

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'


/* Page About */
class About extends Component {

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
    }

    render = () => {

        const meta = {
            title: `О компании | General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехника Алматы, реализация спецтехники, оборудование для различных отраслей, качество и надежность, продажа техники Алматы, строительное оборудование, сельскохозяйственная техника, горная техника, транспортные средства, услуги по продаже спецтехники, специализированное оборудование'
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header back />

                    <div className="page-content">
                        <h1>О компании</h1>

                        <div className="about">

                            <div className="about-information">
                                <div className="about-information-text">
                                    <p>ТОО «GENERAL MACHINERY GROUP» — это компания, которая с 2019 года занимается реализацией спецтехники на рынке. За годы своей работы компания зарекомендовала себя как надежный и ответственный поставщик, специализирующийся на дорожно-строительной, карьерной и подземной технике. В 2020 году компания стала официальным представителем Sinotruk Group, также известной как China National Heavy Duty Truck Group. Это позволило «GENERAL MACHINERY GROUP» расширить ассортимент и предложить своим клиентам спецтехнику из линейки HOWO, которая отличается высокой надежностью, качеством и производительностью.</p>
                                    <h3>Направления деятельности</h3>
                                    <p>Одним из ключевых направлений деятельности компании является продажа различной спецтехники. Компания предлагает широкий выбор техники для строительных и дорожных работ, а также для использования в карьерах и подземных разработках. В ассортимент входят экскаваторы, бульдозеры, самосвалы, погрузчики, автокраны, грейдеры и многое другое. Каждая единица техники, предлагаемая «GENERAL MACHINERY GROUP», проходит строгий контроль качества, что гарантирует ее надежность и долговечность.</p>
                                    <p>Кроме того, компания активно занимается реализацией грузовой техники, включая грузовики и тягачи марки HOWO, которая является одной из самых популярных и востребованных на рынке. Спецтехника HOWO известна своей высокой производительностью, надежностью и долговечностью, что делает ее идеальным выбором для различных отраслей промышленности и строительства.</p>

                                    <h3>Партнерство с Sinotruk Group</h3>

                                    <p>Сотрудничество с Sinotruk Group открыло новые возможности для «GENERAL MACHINERY GROUP». Статус официального представителя позволил компании не только расширить ассортимент предлагаемых товаров, но и предложить своим клиентам более выгодные условия покупки. Компания обеспечивает полный цикл обслуживания техники, начиная от консультаций и подбора необходимого оборудования до его поставки и технического обслуживания. Это позволяет клиентам быть уверенными в качестве приобретенной техники и ее длительном сроке службы.</p>

                                    <h3>Клиентский сервис и подход</h3>

                                    <p>Одним из главных приоритетов «GENERAL MACHINERY GROUP» является удовлетворение потребностей клиентов. Компания стремится к тому, чтобы каждый клиент, обращаясь к ним, получал не только качественный продукт, но и высокий уровень сервиса. Для этого в компании работает команда квалифицированных специалистов, готовых в любое время проконсультировать и помочь в выборе техники, отвечающей всем требованиям и пожеланиям клиента.</p>

                                    <p>Индивидуальный подход к каждому клиенту — это не просто слова для «GENERAL MACHINERY GROUP». Компания учитывает все пожелания и особенности работы каждого клиента, предлагая оптимальные решения, которые соответствуют их нуждам. Гибкая ценовая политика позволяет компании предложить конкурентоспособные цены на весь ассортимент продукции, что делает покупку техники максимально выгодной.</p>

                                </div>

                                <div className="about-video">
                                    <ReactPlayer
                                        url={`${env.mediapoint}/video/about.mov`}
                                        width="100%"
                                        height="100%"
                                        controls={true}
                                    />
                                </div>
                            </div>



                            <div className="about-information reverse">

                                <div className="about-video">
                                    <ReactPlayer
                                        url={`${env.mediapoint}/video/svh.mov`}
                                        width="100%"
                                        height="100%"
                                        controls={true}
                                    />
                                </div>

                                <div className="about-information-text">

                                    <h3 style={{ marginTop: 10 }}>Гибкость в условиях рынка</h3>

                                    <p>«GENERAL MACHINERY GROUP» успешно работает как в условиях экономического кризиса, так и в периоды стабильного роста рынка. Компания адаптирует свою деятельность под текущие рыночные условия, предлагая наиболее актуальные и востребованные решения. Это позволяет не только удерживать позиции на рынке, но и активно развиваться, предлагая своим клиентам новые продукты и услуги.</p>

                                    <p>Компания не боится трудностей и всегда готова к новым вызовам. Работа в условиях экстремальных температур, будь то сильная жара или мороз, а также в условиях нестабильной экономической ситуации — все это не мешает «GENERAL MACHINERY GROUP» продолжать свою деятельность и обеспечивать клиентов качественной техникой и сервисом.</p>

                                    <h3>Вклад в развитие рынка</h3>

                                    <p>«GENERAL MACHINERY GROUP» не просто продает технику, но и активно участвует в формировании и развитии культуры рынка продаж спецтехники. Компания стремится внести свой вклад в создание прозрачного и цивилизованного рынка, где основными приоритетами являются качество продукции и удовлетворение потребностей клиентов. Для этого компания постоянно совершенствует свои процессы, внедряет новые технологии и улучшает качество обслуживания.</p>

                                    <p>Важным аспектом деятельности компании является создание долговременных отношений с клиентами и партнерами. «GENERAL MACHINERY GROUP» стремится быть не просто поставщиком техники, но и надежным партнером, готовым всегда прийти на помощь и предложить лучшее решение. Клиенты компании могут быть уверены, что, обратившись к «GENERAL MACHINERY GROUP», они получат не только качественную технику, но и профессиональную поддержку на всех этапах сотрудничества.</p>

                                    <h3>Благодарность и доверие</h3>

                                    <p>Компания «GENERAL MACHINERY GROUP» искренне благодарит всех своих клиентов за выбор и доверие. Каждая покупка, сделанная у нас, — это не просто сделка, а вклад в развитие долгосрочного и взаимовыгодного сотрудничества. Мы гордимся тем, что наши клиенты выбирают нас, и готовы делать все возможное, чтобы оправдать их доверие и ожидания.</p>

                                    <p>Ваша поддержка и уверенность в нашем профессионализме вдохновляют нас на дальнейшее развитие и совершенствование. Мы всегда стремимся к тому, чтобы быть лучшими в своем деле и предоставлять нашим клиентам только самые качественные продукты и услуги.</p>

                                    <h3>Заключение</h3>

                                    <p>ТОО «GENERAL MACHINERY GROUP» — это не просто компания по продаже спецтехники. Это команда профессионалов, преданных своему делу, которые всегда готовы предложить лучшее решение для своих клиентов. Независимо от того, нужна ли вам техника для строительства, дорожных работ или карьеров, «GENERAL MACHINERY GROUP» всегда предложит вам то, что нужно. Мы благодарим вас за выбор нашей компании и готовы продолжать радовать вас качественной техникой и отличным сервисом!</p>

                                </div>

                            </div>
                        </div>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta >
        )
    }

}

export default About