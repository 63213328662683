import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"
import { Slider, Categories, Products, Partners, Advantages, Brands, Parts } from '../../widgets/Home'

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* Components */
import { Button } from "../../components"

/* Helpers */
import { withRouter } from '../../helpers'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'


/* Page Home */
class Home extends Component {

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
    }

    render = () => {

        const meta = {
            title: `General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехника Алматы, реализация спецтехники, оборудование для различных отраслей, качество и надежность, продажа техники Алматы, строительное оборудование, сельскохозяйственная техника, горная техника, транспортные средства, услуги по продаже спецтехники, специализированное оборудование'
                }
            }
        }

        const { navigate } = this.props

        return (
            <DocumentMeta {...meta}>

                <div className="page home-page">

                    <Header dark />

                    {/* Slider */}
                    <Slider />

                    {/* Categories */}
                    <Categories />

                    {/* Products */}
                    <Products />

                    {/* SVH */}
                    <div className="home-svh">
                        <div className="home-svh-wrapper">
                            <h3>Склад временного хранения (СВХ)</h3>

                            <div className="home-svh-information">
                                <div className="home-svh-title">Впервые в Казахстане вы можете воспользоваться услугой растоможки под ключ по принципу «одного окна»!</div>
                                <div className="home-svh-text">Наша компания имеет СВХ с собственной лабораторией!</div>
                            </div>

                            <Button onClick={() => navigate("/svh")} top={40} width={200}>Подробнее</Button>
                        </div>
                    </div>

                    {/* Parts */}
                    <Parts />

                    {/* Partners */}
                    <Partners />

                    {/* Advantages */}
                    <Advantages />

                    {/* Brands */}
                    <Brands />

                    <Footer main />

                </div>
            </DocumentMeta>
        )
    }

}

export default withRouter(Home)