import React, { Component } from "react"

/* React router */
import { Link } from "react-router-dom"

/* Widgets */
import { Header, Footer } from '../../widgets/Page'

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'

/* Page 404 */
class Page404 extends Component {

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
    }

    render = () => {

        const meta = {
            title: `Страница не найдена | General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехника Алматы, реализация спецтехники, оборудование для различных отраслей, качество и надежность, продажа техники Алматы, строительное оборудование, сельскохозяйственная техника, горная техника, транспортные средства, услуги по продаже спецтехники, специализированное оборудование'
                }
            }
        }


        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header />

                    <div className="page-content page-404">
                        <div className="page-404-title">404</div>
                        <div className="page-404-text">Страница не найдена</div>
                        <Link to="/" className="page-404-button">На главную</Link>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta>
        )
    }

}

export default Page404