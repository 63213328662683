import React, { Component } from "react"

/* Redux */
import { connect } from "react-redux"

/* Helpers */
import { withRouter } from '../../helpers'


/* Widget Parts */
class Parts extends Component {

    request = () => {

        /* Fields */
        const { setMessage, setRequest } = this.props

        const url = `Заявка на запчасти`

        setMessage(url)
        setRequest(true)
    }

    render = () => {
        return (
            <div className="home-parts">
                <div className="home-parts-wrapper">
                    <h3>Запчасти для спецтехники</h3>
                    <div className="home-parts-container">

                        <div onClick={() => this.request()} className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/spare-parts.png" alt="Settings" />
                                <div className="home-parts-item-text">Запчасти</div>
                            </div>
                        </div>

                        <div onClick={() => this.request()} to="/parts/category/tormoznaya-sistema" className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/disc-brake.png" alt="Settings" />
                                <div className="home-parts-item-text">Тормозная система</div>
                            </div>
                        </div>

                        <div onClick={() => this.request()} to="/parts/category/masla" className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/engine-oil.png" alt="Settings" />
                                <div className="home-parts-item-text">Масла</div>
                            </div>
                        </div>

                        <div onClick={() => this.request()} to="/parts/category/shiny" className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/car.png" alt="Settings" />
                                <div className="home-parts-item-text">Шины</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        request: state.request,
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data }),
        setMessage: data => dispatch({ type: 'SET_MESSAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Parts))