import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"

/* Player */
import ReactPlayer from 'react-player'

/* Redux */
import { connect } from "react-redux"

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'

/* Page Services */
class Services extends Component {

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
    }

    request = text => {

        /* Fields */
        const { setMessage, setRequest } = this.props

        const message = `Заявка на услугу: ${text}`

        setMessage(message)
        setRequest(true)

    }

    render = () => {

        const meta = {
            title: `Сервисы | General Machinery Group`,
            description: 'Впервые в Казахстане доступна услуга растоможки под ключ по принципу «одного окна»! Склад временного хранения (СВХ) предоставляет специально оборудованные помещения и открытые площадки для хранения товаров и транспортных средств под таможенным контролем, обеспечивая безопасность и удобство на всех этапах таможенного оформления.',
            canonical: `${env.link}/services`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'растоможка под ключ, Казахстан, принцип одного окна, склад временного хранения, СВХ, таможенный контроль, хранение товаров, транспортные средства, зона таможенного контроля, услуги таможенного оформления, логистика Казахстана'
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header />

                    <div className="page-content">
                        <h1>Склад временного хранения (СВХ)</h1>
                        <div className="services">
                            <div className="services-wrapper">

                                <div className="service-information">

                                    <div className="service-information-text">

                                        <div className="service-information-danger">
                                            <div className="service-information-danger-title">Впервые в Казахстане вы можете воспользоваться услугой растоможки под ключ по принципу «одного окна»!</div>
                                            <div className="service-information-danger-text">Наша компания имеет СВХ с собственной лабораторией!</div>
                                        </div>

                                        <p>Склад временного хранения (СВХ) - это специально выделенное и оборудованное помещение или открытая площадка, являющаяся зоной таможенного контроля, предназначенная для хранения товаров и транспортных средств, находящихся под таможенным контролем.</p>

                                        <p>Мы предоставляем услуги
                                            хранения товаров и
                                            лаборатории, которая предоставляет растоможку легковых ТС, тяжелой специализированной и колесной техники .</p>

                                        <p>В наши услуги входят:</p>
                                        <p>•⁠  Хранение на СВХ (зона таможенного контроля) - предоставление крытых, скалдских помещений и открытая зона;</p>
                                        <p>•⁠  Оформление товара в таможенных органах;</p>
                                        <p>•⁠  Брокерские услуги;</p>
                                        <p>•⁠  Услуги лаборатории по предоставлению свидетльтсва безопасности конструкции траспортных средств (СБКТС);</p>
                                        <p>•⁠  Оплата утильзационных платежей; Получение первичной регистрации тс (техпаспорт).</p>

                                    </div>

                                    <div className="service-information-video">
                                        <ReactPlayer
                                            url={`${env.mediapoint}/video/svh.mov`}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta>
        )
    }

}

const mapStateToProps = state => {
    return {
        request: state.request,
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data }),
        setMessage: data => dispatch({ type: 'SET_MESSAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)