import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

/* Swipeable */
import { useSwipeable } from 'react-swipeable'

const data = [
    {
        image: 'slide1.jpg',
        title: 'Идеальный Грузовик Для Вашего Бизнеса',
        text: 'Мы предлагаем широкий выбор грузовиков, от мощных тягачей до универсальных платформ. Каждый грузовик проверен на качество и готов к работе.',
        button: 'Посмотреть все модели',
        link: '/catalog'
    },
    {
        image: 'slide2.jpg',
        title: 'Ваш Грузовик – Ваш Успех',
        text: 'Выбирайте грузовики, которые соответствуют самым высоким стандартам. Мы предлагаем технику, которая выдержит любые нагрузки и поможет вашему бизнесу двигаться вперед.',
        button: 'Получить Консультацию',
        link: '/contacts'
    },
    {
        image: 'slide3.jpg',
        title: 'Премиум Качество по Доступной Цене',
        text: 'Только в нашем магазине вы найдете грузовики по привлекательным ценам. Специальные предложения и скидки на новые и подержанные модели.',
        button: 'Связаться с менеджером',
        link: '/contacts'
    },
    {
        image: 'slide4.jpg',
        title: 'Гибкость и Комфорт в Каждой Детали',
        text: 'Грузовики, которые подходят именно вам! Выбирайте из широкого ассортимента и настраивайте грузовики под свои нужды. Мы предлагаем индивидуальные решения для любого бизнеса.',
        button: 'Выбрать Грузовик',
        link: '/catalog'
    },
    {
        image: 'slide5.jpg',
        title: 'Склад временного хранения (СВХ)',
        text: 'Впервые в Казахстане вы можете воспользоваться услугой растоможки под ключ по принципу «одного окна»!',
        button: 'Подробнее',
        link: '/svh'
    }
]

const WRAPPER = 1240

/* Widget Slider */
const Slider = () => {

    /* Hooks */
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [index, setIndex] = useState(0)

    /* Swipe Handlers */
    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(index + 1),
        onSwipedRight: () => updateIndex(index - 1),
    })

    /* Window Resize Events and Auto Play */
    useEffect(() => {

        const interval = setInterval(() => {
            updateIndex(index + 1)
        }, 7000)

        window.addEventListener('resize', updateWindowDimensions)

        return () => {

            if (interval) {
                clearInterval(interval)
            }

            window.removeEventListener('resize', updateWindowDimensions)
        }

    })

    /* Window Resize Handler */
    const updateWindowDimensions = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    /* Change Carousel Index */
    const updateIndex = i => {

        if (Array.isArray(data) && data.length > 1) {

            if (i < 0) {
                i = data.length - 1
            }
            else if (i >= data.length) {
                i = 0
            }

            setIndex(i)
        }
    }


    let dist = 20
    let top = height / 2 - data.length * 40 / 2

    if (width > WRAPPER) {
        dist = (width - WRAPPER) / 2 + 20
    }

    if (width < 768) {
        top = height * 0.3
    }


    return (
        <div className="home-slide" {...handlers}>

            <div className="slider-shadow" />

            <div className="home-slide-inner" style={{ transform: `translateX(-${width * index}px)` }}>
                {Array.isArray(data) && data.length > 0 && data.map((item, index) =>
                    <div className="home-slide-item" key={`${index}`}>
                        <div className="home-slide-item-inner">
                            {item.image ? <img src={`/slides/${item.image}`} alt="Slide" /> : null}
                            <div className="home-slide-item-shadow" />
                            <div className="home-slide-item-box" style={{ left: dist }}>
                                <div className="home-slide-item-title">{item.title}</div>
                                <div className="home-slide-item-text">{item.text}</div>
                                <Link to={`${item.link}`} className="home-slide-item-button">{item.button}</Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="home-slider-numbers" style={width > 768 ? { top, bottom: top } : { bottom: 10, left: width / 2 - data.length * 40 / 2 }}>
                {data.map((_, index) =>
                    <div onClick={() => updateIndex(index)} className="home-slider-number" key={`${index}`}>
                        {index > 10 ? index + 1 : `0${index + 1}`}
                    </div>
                )}
                <div className="home-slider-number-active" style={width > 768 ? { top: index * 40 } : { left: index * 40 }} />
            </div>

        </div>
    )

}

export default Slider