import React, { Component } from "react"

/* Router */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

/* Pages */
import { Page404, Home, About, Contacts, Leasing, Catalog, Product, Services, Search, SVH } from './pages/Home'
import { MobileParts, MobileCatalog, MobileMore } from './pages/Mobile'

/* Redux */
import { Provider } from 'react-redux'
import { store } from './store'

/* Widgets */
import Request from "./widgets/Page/Request"

/* Components */
import { MobileTab } from './components'

/* Modal */
import Modal from "react-modal"

/* Yandex Metrika */
import { YMInitializer } from 'react-yandex-metrika'

/* Google Analytics */
import ReactGA from "react-ga4"

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'


/* Default Settings */
Modal.setAppElement("#root")

/* Google Analytics Settings */
ReactGA.initialize("your GA measurement id")



/* Entry Point */
class App extends Component {

    componentDidMount = () => {
        ReactPixel.init('803388923863609', { em: 'developerkz44@gmail.com' }, { autoConfig: true, debug: false })
    }

    render = () => {
        return (
            <Provider store={store}>
                <Router>
                    <Routes>

                        <Route path="*" element={<Page404 />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/search/:text" element={<Search />} />
                        <Route path="/leasing" element={<Leasing />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/svh" element={<SVH />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/catalog" element={<Catalog type="truck" />} />
                        <Route path="/catalog/category/:slug" element={<Catalog type="truck" />} />
                        <Route path="/parts" element={<Catalog type="parts" />} />
                        <Route path="/parts/category/:slug" element={<Catalog type="parts" />} />

                        <Route path="/product/:slug" element={<Product type="parts" />} />

                        <Route path="/mobile/parts" element={<MobileParts />} />
                        <Route path="/mobile/catalog" element={<MobileCatalog />} />
                        <Route path="/more" element={<MobileMore />} />

                    </Routes>

                    <Request />

                    <MobileTab />

                </Router>

                <YMInitializer accounts={[98584449]} />

            </Provider>
        )
    }

}

export default App