import React, { Component } from "react"

/* Components */
import { Logo } from '../../components'

/* Router */
import { Link } from 'react-router-dom'

/* Redux */
import { connect } from "react-redux"

/* Helpers */
import { withRouter } from '../../helpers'


/* Fields */
const MENU = [
    { link: "/catalog", mobileLink: "/mobile/catalog", name: "СпецТехника" },
    { link: "/parts", mobileLink: "/mobile/parts", name: "Запчасти" },
    { link: "/svh", mobileLink: "/svh", name: "СВХ" },
    { link: "/leasing", mobileLink: "/leasing", name: "Лизинг" },
    { link: "/services", mobileLink: "/services", name: "Сервисы" },
    { link: "/about", mobileLink: "/about", name: "О компании" },
    { link: "/contacts", mobileLink: "/contacts", name: "Контакты" },
]



/* Widget Header */
class Header extends Component {

    constructor(props) {
        super(props)

        this.state = {
            search: props.search ? props.search : "",
            active: true,
            mobileSearch: false,
            menu: false
        }
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.sticky)
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.sticky)
    }

    /* Scroll Event Listener */
    sticky = () => {

        const header = document.querySelector('.header')
        const scrollTop = window.scrollY

        if (scrollTop >= 50) {
            this.setState({ active: false })
            header.classList.add('is-sticky')
        }
        else {
            this.setState({ active: true })
            header.classList.remove('is-sticky')
        }

    }

    search = () => {
        const { navigate } = this.props
        const { search } = this.state
        navigate(`/search/${search}`)
    }

    onSubmit = e => {
        e.preventDefault()
        this.search()
    }

    render = () => {

        const { dark, back, navigate, setRequest } = this.props
        const { active, mobileSearch, menu, search } = this.state

        return (
            <div className={`header ${dark ? 'header-dark' : 'default'}`}>

                <div className={`top ${mobileSearch ? 'search-box' : 'hidden'}`}>
                    <div className="top-wrapper">

                        <div className="top-social">

                            <a href="https://www.instagram.com/general_machinery_group/" target="_black">
                                <img src="/images/social/instagram.png" alt="Instagram" />
                            </a>

                            <a href="https://youtube.com" target="_black">
                                <img src="/images/social/youtube.png" alt="Youtube" />
                            </a>

                            <a href="https://go.2gis.com/kqqlv" target="_black" className="map" >
                                <img src="/images/social/2gis.jpg" alt="2GiS" />
                            </a>

                        </div>

                        <div className="top-menu">
                            {MENU.map((item, index) =>
                                <Link key={`${index}`} to={item.link}>{item.name}</Link>
                            )}
                        </div>

                        {back
                            ?
                            <div onClick={() => navigate(-1)} className="top-back">
                                <img src="/images/arrow.png" alt="More" />
                            </div>
                            :
                            <Link to="/" className="top-logo">
                                <Logo size={45} color={dark ? (active ? "#fff" : "#222") : "#222"} dcolor={dark ? (active ? "#fff" : "#9A0B0D") : "#9A0B0D"} />
                                <div className="top-logo-text">General Machinery Group</div>
                            </Link>
                        }


                        <div className="top-buttons">

                            <div onClick={() => this.setState({ mobileSearch: !mobileSearch })} className="top-menu-burger">
                                <img src="/images/search.png" alt="More" />
                            </div>

                            <div onClick={() => this.setState({ menu: true })} className="top-menu-burger">
                                <img src="/images/more.png" alt="More" />
                            </div>

                        </div>

                    </div>
                </div>

                <div className={`header-wrapper ${mobileSearch ? 'visible' : 'hidden'}`}>

                    <div className="header-container">

                        <Link to="/" className="header-logo">
                            <Logo color={dark ? (active ? "#fff" : "#222") : "#222"} dcolor={dark ? (active ? "#fff" : "#9A0B0D") : "#9A0B0D"} />
                            <div className="header-logo-text">General Machinery Group</div>
                        </Link>

                        <form onSubmit={this.submit} className="header-search">
                            <input value={search} onChange={event => this.setState({ search: event.target.value })} type="text" placeholder="Поиск по спецтехнике, запчастям и услугам" />
                            <button onClick={() => this.search()} className="header-search-button">
                                <img src="/images/search.png" alt="Search" />
                            </button>
                        </form>
                    </div>

                    <div className="header-buttons">

                        <a href="tel: +7 (771) 191-01-12" className="header-phone">
                            <img src="/images/phone.png" alt="Phone" />
                            <div className="header-phone-container">
                                <div className="header-phone-text">По всем вопросам: </div>
                                <div className="header-phone-value">+7 (771) 191-01-12</div>
                            </div>
                        </a>

                        <div onClick={() => setRequest(true)} className="header-request">Оставить заявку</div>

                    </div>

                </div>

                <div className={`mobile-menu ${menu ? 'active' : 'hidden'}`}>
                    <div className="mobile-menu-top">
                        <div onClick={() => this.setState({ menu: false })} className="mobile-menu-close">
                            <img src="/images/x.png" alt="X" />
                        </div>
                    </div>
                    <div className="mobile-menu-container">
                        {MENU.map((item, index) =>
                            <Link key={`${index}`} to={item.mobileLink}>{item.name}</Link>
                        )}
                    </div>
                </div>

            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        request: state.request
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))