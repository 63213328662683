import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from '../../widgets/Page'
import { Parts } from '../../widgets/Home'

/* Facebook Pixel */
import ReactPixel from 'react-facebook-pixel'

/* Page Parts */
class MobileParts extends Component {

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        ReactPixel.pageView()
    }

    render = () => {
        return (
            <div className="page">
                <Header back />
                <Parts />
                <Footer />
            </div>
        )
    }
}

export default MobileParts